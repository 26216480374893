<template>
  <TrainerEditTemplate>

    <div class="page-filter">
      <div class="row">
        <div class="col-3">
          <div class="page-filter__label">День недели</div>
          <FveSelectObject
            name="weekday"
            required
            v-model="table.filterCache.weekday"
            :optionList="days"
            :optionGetKey="(obj) => { return obj.value.toString();}"
            :optionGetName="(obj) => { return obj.name;}"
          />
        </div>
        <!--
        <div class="col-2">
          <div class="page-filter__label">Дата</div>
          <FveDatePicker
            v-model="table.filterCache.date"
          />
        </div>
        -->
        <div class="col-4">
          <div class="page-filter__label">Время</div>
          <div class="page-filter__field-gp">
            <FveTime
              placeholder="От"
              v-model="table.filterCache.start_time"
            />
            <div class="page-filter__caret"></div>
            <FveTime
              placeholder="До"
              v-model="table.filterCache.end_time"
            />
          </div>
        </div>
        <div class="col-3">
          <b-button size="lg" variant="primary" class="mt-fix" @click="applyFilter">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4.5 8.483v-7.14" stroke="#1B1B1B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path clip-rule="evenodd" d="M4.5 19.342a2.843 2.843 0 110-5.685 2.843 2.843 0 010 5.685z" stroke="#1B1B1B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M15.5 12.2v7.143" stroke="#1B1B1B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path clip-rule="evenodd" d="M15.5 1.343a2.842 2.842 0 10-.001 5.684 2.842 2.842 0 00.001-5.684z" stroke="#1B1B1B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
            Отфильтровать
          </b-button>
        </div>
      </div>
    </div>

    <b-table
      id="table"
      :fields="table.header"
      :items="table.rows"
      hover
      striped
      borderless
      dark
      no-local-sorting
      @sort-changed="sortChanged"
    ></b-table>
    <b-pagination
      v-if="table.page.count > table.page.limit"

      v-model="table.page.current"
      :total-rows="table.page.count"
      :per-page="table.page.limit"
      @change="paginationChange"

      aria-controls="table"
      align="right"
      last-class="hidden"
      first-class="hidden"
      first-number
      last-number
    ></b-pagination>

  </TrainerEditTemplate>
</template>

<script>

import TrainerEditTemplate from "@page/Trainer/_component/TrainerEditTemplate";

import FveTime from "@widgetFormValidate/src/Element/Text/FveTime";
import FveDatePicker from "@widgetFormValidate/src/Element/Date/FveDatePicker";
import FveSelectObject from "@widgetFormValidate/src/Element/Select/vue-multiselect/FveSelectObject";

export default {
  name: "TrainerViewTimetable",
  components: {
    TrainerEditTemplate,
    FveTime,
    //FveDatePicker,
    FveSelectObject,
  },
  props: {
    trainerId: [String, Number]
  },
  data(){
    return {
      days: [
        {
          value: 1,
          name: 'Воскресенье'
        },
        {
          value: 2,
          name: 'Понедельник'
        },
        {
          value: 3,
          name: 'Вторник'
        },
        {
          value: 4,
          name: 'Среда'
        },
        {
          value: 5,
          name: 'Четверг'
        },
        {
          value: 6,
          name: 'Пятница'
        },
        {
          value: 7,
          name: 'Суббота'
        },
      ],
      table: {
        page: {
          current : 1,
          limit   : 15,
          count   : null,
        },
        sort: {
          by: null,
          desc: false
        },
        header:  [
          { key: 'club', label: 'Клуб',        sortable: true },
          { key: 'hall', label: 'Зал',         sortable: true },
          { key: 'date', label: 'Дата',        sortable: true },
          { key: 'day',  label: 'День недели', sortable: true },
          { key: 'time', label: 'Время',       sortable: true },
          { key: 'rate', label: 'Тариф',       sortable: true },
        ],
        rows: [],
        filter: {
          weekday: {
            value: '',
            name: ''
          },
          start_time: '',
          end_time: '',
          date: '',
        },
        filterCache: {
          weekday: {
            value: '',
            name: ''
          },
          start_time: '',
          end_time: '',
          date: '',
        },
      }
    };
  },
  methods: {
    sortChanged(val) {
      this.table.sort.by    = val.sortBy;
      this.table.sort.desc  = val.sortDesc;
      this.table.rows = [];

      this.load();
    },
    paginationChange(val) {
      this.table.page.current = val;
      this.table.rows = [];

      this.load();
    },
    applyFilter() {
      this.table.page.current = 1;
      this.table.filter = Object.assign({}, this.table.filterCache);
      this.table.rows = [];
      this.load();
    },

    load(){
      let filter = {
        trainer: this.trainerId
      };

      if(this.table.filter.weekday) { filter.weekday = this.table.filter.weekday.value; }
      if(this.table.filter.start_time) { filter.start_time = this.table.filter.start_time; }
      if(this.table.filter.end_time) { filter.end_time = this.table.filter.end_time; }
      //if(this.table.filter.date) { filter.date = this.table.filter.date; }


      RequestManager.Trainer.getTrainerTimetableList(Object.assign( {
        page_limit: this.table.page.limit,
        page      : this.table.page.current,
        ordering  : this.table.sort.by === null ? null : (this.table.sort.desc ? '' : '-') + this.table.sort.by,
      }, filter)).then( (timetableList) => {
        this.table.page.count = timetableList.count;
        this.table.rows = timetableList.results.map((item) => {
          return {
            club  : item.court.club_name || '-',
            hall  : item.court.name,
            date  : DateTime(item.date).format('DD.MM.YYYY'),
            day   : this.days[new Date(item.date).getDay()].name,
            time  : `${item.start_time} - ${item.end_time}`,
            rate  : item.tariff.name,
          };
        });
      });
    },
  },
  created() {
    this.load();
  }
};
</script>

<style lang="scss" scoped>

</style>
